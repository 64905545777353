import {NgModule} from '@angular/core';
import {AppGrantDirective} from './grant.directive';
import {RoutePageDirective} from './routePage.directive';
import {AwsSrcDirective} from "./aws-src.directive";

@NgModule({
  declarations: [
    AppGrantDirective,
    AwsSrcDirective,
    RoutePageDirective
  ],
  exports: [
    AppGrantDirective,
    AwsSrcDirective,
    RoutePageDirective
  ]
})
export class DirectiveModule { }
