import {Injectable} from '@angular/core';
import {BaseApi} from "./base.api";
import {HttpService} from "../service/http.service";

@Injectable()
export class BannerApi extends BaseApi {
  constructor(http: HttpService) {
    super('banner', http);
  }
}
