import {Action} from "@ngrx/store";
import {Login} from "../../model/login";
import {User} from "../../model/user";

export const ACCOUNT_GET_LOGINED = 'ACCOUNT_GET_LOGINED';
export class AccountGetLogined implements Action {
  readonly type = ACCOUNT_GET_LOGINED;
  constructor() {}
}

export const ACCOUNT_GET_LOGINED_SUCCESS = 'ACCOUNT_GET_LOGINED_SUCCESS';
export class AccountGetLoginedSuccess implements Action {
  readonly type = ACCOUNT_GET_LOGINED_SUCCESS;
  constructor(public login: Login) {}
}

export const ACCOUNT_SIGNUP = 'ACCOUNT_SIGNUP';
export class AccountSignup implements Action  {
  readonly type = ACCOUNT_SIGNUP;
  constructor (public user: User) {}
}

export const ACCOUNT_MOBILE_SEND_CODE = 'ACCOUNT_MOBILE_SEND_CODE';
export class AccountMobileSendCode implements Action  {
  readonly type = ACCOUNT_MOBILE_SEND_CODE;
  constructor(public phone: string) {}
}

export const ACCOUNT_MOBILE_CHECK_CODE = 'ACCOUNT_MOBILE_CHECK_CODE';
export class AccountMobileCheckCode implements Action {
  readonly type = ACCOUNT_MOBILE_CHECK_CODE;
  constructor(public code: string) {}
}

export const ACCOUNT_MOBILE_CHECK_CODE_SUCCESS = 'ACCOUNT_MOBILE_CHECK_CODE_SUCCESS';
export class AccountMobileCheckCodeSuccess implements Action {
  readonly type = ACCOUNT_MOBILE_CHECK_CODE_SUCCESS;
  constructor(public result: boolean) {
  }
}

/* 주소 */
export const ACCOUNT_ADDRESS_SET_CODE = 'ACCOUNT_ADDRESS_SET_CODE';
export class AccountAddressSetCode implements Action {
  readonly type = ACCOUNT_ADDRESS_SET_CODE;
  constructor(public postcode: string, public address: string) {}
}

export const ACCOUNT_ADDRESS_RESET_CODE = 'ACCOUNT_ADDRESS_RESET_CODE';
export class AccountAddressResetCode implements Action {
  readonly type = ACCOUNT_ADDRESS_RESET_CODE;
  constructor() {}
}

export const ACCOUNT_ME = 'ACCOUNT_ME';
export class AccountMe implements Action {
  readonly type = ACCOUNT_ME;
  constructor() {}
}

export const ACCOUNT_GET_ME = 'ACCOUNT_GET_ME';
export class AccountGetMe implements Action {
  readonly type = ACCOUNT_GET_ME;
  constructor(public user: User) {}
}

export const ACCOUNT_CALL = 'ACCOUNT_CALL';
export class AccountCall implements Action {
  readonly type = ACCOUNT_CALL;
  constructor(public id: string) {}
}

export const ACCOUNT_CALL_SUCCESS = 'ACCOUNT_CALL_SUCCESS';
export class AccountCallSuccess implements Action{
  readonly type = ACCOUNT_CALL_SUCCESS;
  constructor(public user: User) {}
}

export const PUT_PHONE = 'PUT_PHONE';
export class PutPhone implements Action {
  readonly type = PUT_PHONE;
  constructor(public phone: string){}
}

export const PUT_PASS = 'PUT_PASS';
export class PutPass implements Action{
  readonly type = PUT_PASS;
  constructor(public oldPass: string, public newPass: string) {}
}

export const ACCOUNT_PAYMENT ='ACCOUNT_PAYMENT';
export class AccountPayment implements Action{
  readonly type = ACCOUNT_PAYMENT;
  constructor(public payment: string){}
}

export const ACCOUNT_PAYMENT_SUCCESS = 'ACCOUNT_PAYMENT_SUCCESS';
export class AccountPaymentSuccess implements Action{
  readonly type = ACCOUNT_PAYMENT_SUCCESS;
  constructor(public result: string){}
}

export const ACCOUNT_PAYMENT_REQUEST = 'ACCOUNT_PAYMENT_REQUEST';
export class AccountPaymentRequest implements Action{
  readonly type = ACCOUNT_PAYMENT_REQUEST;
  constructor(public id: string) {}
}

export const ACCOUNT_PAYMENT_REQUEST_SUCCESS = 'ACCOUNT_PAYMENT_REQUEST_SUCCESS';
export class AccountPaymentRequestSuccess implements Action{
  readonly type = ACCOUNT_PAYMENT_REQUEST_SUCCESS;
  constructor(public data: string){}
}

export type All =
  AccountSignup |
  AccountMobileSendCode |
  AccountMobileCheckCode |
  AccountMobileCheckCodeSuccess |
  AccountGetLogined |
  AccountGetLoginedSuccess |
  AccountCall |
  AccountCallSuccess |
  AccountAddressSetCode |
  AccountAddressResetCode |
  AccountMe |
  AccountGetMe |
  PutPhone |
  PutPass |
  AccountPayment |
  AccountPaymentSuccess |
  AccountPaymentRequest |
  AccountPaymentRequestSuccess
  ;
