import {Map} from "immutable";
import {Clue} from "../../model/clue";

export interface IClueState extends Map<string, any> {
  me: Clue[],
  search: Clue[],
  detail: Clue,
  call: number,
  select: Clue
}

export const ClueState = Map({
  me: [],
  search: null,
  detail: null,
  call: null,
  select: null
});
