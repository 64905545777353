import {NgModule} from '@angular/core';
import {ClueApi} from "./clue.api";
import {UserApi} from "./user.api";
import {LoginApi} from "./login.api";
import {AuthApi} from "./auth.api";
import {SignApi} from "./sign.api";
import {FileApi} from "./file.api";
import {OrderApi} from "./order.api";
import {ArticleApi} from "./article.api";
import {FaqApi} from "./faq.api";
import {InquiryApi} from "./inquiry.api";
import {FoundApi} from "./found.api";
import {PaymentApi} from "./payment.api";
import {ProductApi} from "./product.api";
import {BankApi} from "./bank.api";
import {BannerApi} from "./banner.api";

@NgModule({
  imports: [],
  providers: [
    ClueApi,
    UserApi,
    LoginApi,
    AuthApi,
    SignApi,
    FileApi,
    OrderApi,
    ArticleApi,
    FaqApi,
    InquiryApi,
    FoundApi,
    PaymentApi,
    ProductApi,
    BankApi,
    BannerApi
  ]
})
export class ApiModule {
}
