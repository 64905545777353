import {Injectable} from "@angular/core";
import {HttpService} from "../service/http.service";
import {BaseApi} from "./base.api";
import {Observable} from "rxjs/Observable";
import {IResponse} from "../service/response.service";

@Injectable()
export class OrderApi extends BaseApi {

  constructor(http: HttpService) {
    super('orders', http);
  }

  public payment(orders: any, receiver: any, total: number, types: string, bank?:string): Observable<IResponse<any>> {
    return this.http.post<IResponse<any>>(`${this.controllerName}`, {orders: orders, receiver: receiver, total: total, type: types, bank: bank});
  }

  public me(): Observable<IResponse<any>>{
    return this.http.get<IResponse<any>>(`${this.controllerName}/me`,{});
  }

  public update(state: string, id: string): Observable<IResponse<any>>{
    return this.http.put<IResponse<any>>(`${this.controllerName}/${id}`, {state: state});
  }

  public select(id: string): Observable<IResponse<any>>{
    return this.http.get(`${this.controllerName}/${id}`, {});
  }

  public ping(order_id: string): Observable<IResponse<any>>{
    return this.http.get(`${this.controllerName}/${order_id}/ping`, {dummy: new Date().getTime()});
  }
}
