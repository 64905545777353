import {NgModule, Optional, SkipSelf} from '@angular/core';
import {ClueApi} from "./api/clue.api";
import {ApiModule} from "./api/api.module";
import {ServiceModule} from "./service/service.module";
import {CanActiveViaAuthGuard} from "./guard/auth.guard";

@NgModule({
  imports: [
    ApiModule,
    ServiceModule,
  ],
  exports: [

  ],
  declarations: [],
  entryComponents: [],
  providers: [
    CanActiveViaAuthGuard
  ]
})

export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) { }
}
