import {Injectable} from "@angular/core";
// import {MediaService} from "../../../core/api/media.service";
import {InquiryApi} from "../../api/inquiry.api";
import {Actions, createEffect, Effect, ofType} from "@ngrx/effects";

import * as InquiryActions from './inquiry.action';
import * as RouterActions from '../../../core/redux/router/router.action';

import {IResponse, RESPONSE_CODE} from "../../service/response.service";
import {Inquiry} from "../../model/inquiry";
import {Converter} from "../../helper/converter";
import * as ClueActions from "../clue/clue.action";
import {map, switchMap} from "rxjs/operators";
import {ToastService} from "../../service/toast.service";

@Injectable()
export class InquiryEffect {


  listMe$ = createEffect(() => this.actions$.pipe(
    ofType(InquiryActions.INQUIRY_GET_ME),
    switchMap((action: InquiryActions.InquiryGetMe) => {
      return this.inquiryApi.me(action.offset, action.count)
        .pipe(
          map((res:any) => {
            if (res.code === RESPONSE_CODE.SUCCESS) {
              const list: Array<Inquiry> = Converter.jsonToInstance(Inquiry, res.data);
              return new InquiryActions.InquiryGetMeSuccess(res.data.total, list)
            }
            return {type: 'NO_ACTION'};
          })
        )
    })));

  // add$ = createEffect(() => this.actions$.pipe(
  //     ofType(ClueActions.CLUE_ADD),
  //     switchMap((action: ClueActions.ClueAdd) => {
  //       return this.clueApi.add(action.clue)
  //         .pipe(
  //           map((res: IResponse<any>) => {
  //             if (res.code === RESPONSE_CODE.SUCCESS) {
  //               return new RouterActions.Go(['user/clue/list']);
  //             } else {
  //               if (res.code == RESPONSE_CODE.INVALID_PARAMETER) {
  //                 this.toastService.show('모든 항목을 입력해주세요.', 'toast1');
  //               } else {
  //                 this.toastService.show(res.msg, 'toast1');
  //               }
  //               return {type: "NO_ACTION"};
  //             }
  //           }))
  //     })
  //   ),
  //   {dispatch: false}
  // )

  // @Effect() InquiryGetList$ = this.actions$
  //   .ofType(InquiryActions.INQUIRY_GET_LIST)
  //   .switchMap((action: InquiryActions.InquiryGetList) => {
  //     return this.inquiryService.list(action.offset, action.count)
  //       .map((res: any) => {
  //         if (res.code === RESPONSE_CODE.SUCCESS) {
  //           const list: Array<Inquiry> = Converter.jsonToInstance(Inquiry, res.data.list);
  //           return new InquiryActions.InquiryGetListSuccess(res.data.total, list)
  //         }
  //         return {type: 'NO_ACTION'};
  //       })
  //   });
  //
  // @Effect() InquiryGetMe$ = this.actions$
  //   .ofType(InquiryActions.INQUIRY_GET_ME)
  //   .switchMap((action: InquiryActions.InquiryGetMe) => {
  //     return this.inquiryService.me(action.offset, action.count)
  //       .map((res:any) => {
  //         if (res.code === RESPONSE_CODE.SUCCESS) {
  //           const list: Array<Inquiry> = Converter.jsonToInstance(Inquiry, res.data);
  //           console.log(list);
  //           return new InquiryActions.InquiryGetMeSuccess(res.data.total, list)
  //         }
  //         return {type: 'NO_ACTION'};
  //       })
  //   });
  //
  // @Effect() InquiryGetDetail$ = this.actions$
  //   .ofType(InquiryActions.INQUIRY_GET_DETAIL)
  //   .switchMap((action: InquiryActions.InquiryGetDetail) => {
  //     return this.inquiryService.get(action.inquiry_id)
  //       .map((res: any) => {
  //         if (res.code === RESPONSE_CODE.SUCCESS) {
  //           const inquiry: Inquiry = Converter.jsonToInstance(Inquiry, res.data);
  //           return new InquiryActions.InquiryGetDetailSuccess(inquiry);
  //         }
  //         return {type: 'NO_ACTION'};
  //       })
  //   });
  //
  // @Effect() InquiryAdd$ = this.actions$
  //   .ofType(InquiryActions.INQUIRY_ADD)
  //   .switchMap((action: InquiryActions.InquiryAdd) => {
  //     return this.inquiryService.add(action.inquiry)
  //       .map((res: any) => {
  //         switch (res.code) {
  //           case RESPONSE_CODE.SUCCESS:
  //             return new RouterActions.Go('BoardListComponent');
  //           default:
  //             return {type: 'NO_ACTION'};
  //         }
  //       })
  //   });
  //
  // @Effect() InquiryModify$ = this.actions$
  //   .ofType(InquiryActions.INQUIRY_MODIFY)
  //   .switchMap((action: InquiryActions.InquiryModify) => {
  //     return this.inquiryService.answer(action.inquiry)
  //       .map((res: any) => {
  //         switch (res.code) {
  //           case RESPONSE_CODE.SUCCESS:
  //             this.toast(res.msg);
  //             return new RouterActions.Go('BoardListComponent');
  //           default:
  //             return {type: 'NO_ACTION'};
  //         }
  //       })
  //   });
  //
  // @Effect() InquiryDelete$ = this.actions$
  //   .ofType(InquiryActions.INQUIRY_DELETE)
  //   .switchMap((action: InquiryActions.InquiryDelete) => {
  //     return this.inquiryService.delete(action.inquiry_id)
  //       .map((res: any) => {
  //         switch (res.code) {
  //           case RESPONSE_CODE.SUCCESS:
  //             this.toast(res.msg);
  //             return new RouterActions.Go('BoardListComponent');
  //           default:
  //             return {type: 'NO_ACTION'};
  //         }
  //       })
  //   });


  constructor(
    private actions$: Actions,
    private inquiryApi: InquiryApi,
    private toastService: ToastService
  ) { }
}
