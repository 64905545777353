import {IModel} from "./interface";
import {Serializable} from '../helper/serializable';

export class Clue extends Serializable implements IModel {
  _id: string;
  _u: string;
  title: string;
  contents: string;
  keyword: string;
  state: number;
  imgs: any;
  date: Date;
  address: string;
  call: boolean;
  msg: boolean;
  lost: boolean;
  unique: any;
  position: string;
  lost_date: string;
  lost_position: string;
  lost_title: string;
  lost_content: string;
  writer_name: string;
  writer_pass: string;
  writer_contact: string;
  writer: any;
  car: boolean;


  constructor(

  ) {
    super();
  }

  toObject(): object {
    return {
      _id: this._id,
      title: this.title,
      contents: this.contents,
      keyword: this.keyword,
      state: this.state,
      address: this.address,
      imgs: this.imgs,
      call: this.call,
      msg: this.msg,
      lost: this.lost,
      unique: this.unique,
      position: this.position,
      lost_date: this.lost_date,
      lost_title: this.lost_title,
      lost_content: this.lost_content,
      lost_position: this.lost_position,
      writer_name: this.writer_name,
      writer_pass: this.writer_pass,
      writer_contact: this.writer_contact,
      car: this.car
    };
  }
}
