import {Action} from '@ngrx/store';

export const GO = '[Router] Go';
export class Go implements Action {
  readonly type = GO;
  constructor(public commands: any[], public queryParams?: object, public state?: any, public options?: any) {
  }
}

export const BACK = '[Router] Back';
export class Back implements Action {
  readonly type = BACK;
}

export const ROOT = '[Router] Root';
export class Root implements Action {
  readonly type = ROOT;
}

export const FORWARD = '[Router] Forward';
export class Forward implements Action {
  readonly type = FORWARD;
}

export type All =
  Go |
  Back |
  Forward |
  Root;
