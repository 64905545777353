import {Injectable} from "@angular/core";

import {BaseApi} from "./base.api";
import {HttpService} from "../service/http.service";
import {Observable} from "rxjs/Observable";
import {IResponse} from "../service/response.service";

@Injectable()
export class FoundApi extends BaseApi {

  constructor(http: HttpService) {
    super('founds', http);
  }

  public lost(offset: number, count: number, keyword?: string): Observable<IResponse<any>> {
    return this.http.get(`${this.controllerName}/lost`, {offset: offset, count: count, keyword: keyword});
  }

  public call(id: string): Observable<IResponse<any>> {
    return this.http.get(`${this.controllerName}/call` ,{id: id});
  }

  public check(id: string, pass: string): Observable<IResponse<any>> {
    return this.http.get(`${this.controllerName}/check`, {id: id, pass: pass});
  }
}
