import {Injectable} from '@angular/core';
import {Actions, createEffect, Effect, ofType} from '@ngrx/effects';

import * as AccountActions from './action';
import * as RouterActions from '../../../core/redux/router/router.action';
import {LoginApi} from "../../api/login.api";
import {SessionService} from "../../service/session.service";
import {AuthApi} from "../../api/auth.api";
import {SignApi} from "../../api/sign.api";
import {ToastService} from "../../service/toast.service";
import {IResponse, RESPONSE_CODE} from "../../service/response.service";
import {User} from "../../model/user";
import {Converter} from "../../helper/converter";
import {Login} from "../../model/login";
import * as ClueActions from "../clue/clue.action";
import {catchError, map, switchMap} from "rxjs/operators";
import {UserApi} from "../../api/user.api";
import {of} from "rxjs";

@Injectable()
export class AccountEffect {

  add$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(AccountActions.ACCOUNT_MOBILE_SEND_CODE),
        switchMap((action: AccountActions.AccountMobileSendCode) => {
            return this.authApi.mobileSend(action.phone)
              .pipe(
                map((res: any) => {
                  if (res.code === RESPONSE_CODE.SUCCESS) {
                    this.toastService.show('인증번호가 전송되었습니다.', 'toast1');
                  } else {
                    this.toastService.show(res.msg, 'toast1');
                  }
                  return {type: 'NO_ACTION'};
                }),
                catchError(rs => of(rs))
              )
          }
        ))
  });

  mobileCheckCode$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(AccountActions.ACCOUNT_MOBILE_CHECK_CODE),
        switchMap((action: AccountActions.AccountMobileCheckCode) => {
            return this.authApi.mobileCheck(action.code)
              .pipe(
                map((res: any) => {
                  if (res.code === RESPONSE_CODE.SUCCESS) {
                    this.toastService.show('인증에 성공하였습니다.', 'toast1');
                    return new AccountActions.AccountMobileCheckCodeSuccess(true);
                  } else {
                    this.toastService.show(res.msg, 'toast1');
                    return {type: 'NO_ACTION'};
                  }
                }),
                catchError(rs => of(rs))
              )
          }
        ))
  });

  constructor(
    private actions$: Actions,
    private userApi: UserApi,
    private loginService: LoginApi,
    private sessionService: SessionService,
    private authApi: AuthApi,
    private signService: SignApi,
    private toastService: ToastService,
  ) {
  }

  // @Effect() login$ = this.actions$
  //   .ofType(AccountActions.ACCOUNT_LOGIN)
  //   .switchMap((action: AccountActions.AccountLogin) =>
  //     this.signService.signin(action.id, action.pass)
  //       .map((res: IResponse<any>) => {
  //         if (res.code === RESPONSE_CODE.SUCCESS) {
  //           this.sessionService.init(res.data);
  //           this.toastService.show('로그인에 성공하였습니다.', 'toast1');
  //           return new RouterActions.SetRoot('HomeComponent');
  //         } else {
  //           this.toastService.show('로그인에 실패하였습니다.', 'toast1');
  //           return {type: 'NO_ACTION'};
  //         }
  //       })
  //   );
  //
  // @Effect() me$ = this.actions$
  //   .ofType(AccountActions.ACCOUNT_ME)
  //   .switchMap((action: AccountActions.AccountGetMe) =>
  //     this.userService.me()
  //       .map((res: IResponse<any>) => {
  //         if(res.code === RESPONSE_CODE.SUCCESS) {
  //           const user: User = Converter.jsonToInstance<User>(User, res.data);
  //           return new AccountActions.AccountGetMe(user)
  //         }else{
  //           const user: User = Converter.jsonToInstance<User>(User, res.code);
  //           return new AccountActions.AccountGetMe(user)
  //         }
  //       })
  //   );
  //
  // @Effect() call$ = this.actions$
  //   .ofType(AccountActions.ACCOUNT_CALL)
  //   .switchMap((action: AccountActions.AccountCall) =>
  //     this.userService.sejong(action.id)
  //       .map((res: IResponse<any>) => {
  //         if(res.code === RESPONSE_CODE.SUCCESS) {
  //           const user: User = Converter.jsonToInstance<User>(User, res.data);
  //           return new AccountActions.AccountCallSuccess(user);
  //         }else{
  //           return {type: 'NO_ACTION'};
  //         }
  //       })
  //   );
  //
  // @Effect() putPhone$ = this.actions$
  //   .ofType(AccountActions.PUT_PHONE)
  //   .switchMap((action:AccountActions.PutPhone) =>
  //     this.userService.putPhone(action.phone)
  //       .map((res: IResponse<any>) => {
  //         if(res.code === RESPONSE_CODE.SUCCESS) {
  //           this.toastService.show('정보가 변경되었습니다.', 'toast1');
  //           return {type:'NO_ACTION'};
  //         }else{
  //           return {type:'NO_ACTION'};
  //         }
  //       })
  //   );
  //
  // @Effect() putPass$ = this.actions$
  //   .ofType(AccountActions.PUT_PASS)
  //   .switchMap((action: AccountActions.PutPass) =>
  //     this.userService.pass(action.oldPass, action.newPass)
  //       .map((res: IResponse<any>) => {
  //         if(res.code === RESPONSE_CODE.SUCCESS) {
  //           this.toastService.show('비밀번호가 변경되었습니다.', 'toast1');
  //           return new RouterActions.Back();
  //         }else{
  //           this.toastService.show('비밀번호 입력이 잘못되었습니다.', 'toast1');
  //           return {type:'NO_ACTION'};
  //         }
  //       })
  //   );
  //
  //
  // @Effect() loginGet$ = this.actions$
  //   .ofType(AccountActions.ACCOUNT_GET_LOGINED)
  //   .switchMap((action: AccountActions.AccountGetLogined) =>
  //     this.loginService.loginGet()
  //       .map((res: IResponse<Login>) => {
  //         if (res.code === RESPONSE_CODE.SUCCESS) {
  //           const login: Login = Converter.jsonToInstance<Login>(Login, res.data);
  //           return new AccountActions.AccountGetLoginedSuccess(login)
  //         } else {
  //           return {type: 'NO_ACTION'};
  //         }
  //       })
  //   );
  //
  // @Effect() signup$ = this.actions$
  //   .ofType(AccountActions.ACCOUNT_SIGNUP)
  //   .switchMap((action: AccountActions.AccountSignup) =>
  //     this.userService.add(action.user)
  //       .map((res: IResponse<any>) => {
  //         if (res.code === RESPONSE_CODE.SUCCESS) {
  //           this.toastService.show('회원가입이 완료 되었습니다.', 'toast1');
  //           return new RouterActions.Go('SigninComponent');
  //         } else {
  //           this.toastService.show(res.msg, 'toast1');
  //           return {type: 'NO_ACTION'};
  //         }
  //       })
  //   );

  // add$ = createEffect(() => {
  //   return this.actions$
  //     .pipe(
  //       ofType(AccountActions.ACCOUNT_MOBILE_SEND_CODE),
  //       map((action: AccountActions.AccountMobileSendCode) => {
  //           return this.authService.mobileSend(action.phone)
  //             .pipe(
  //               map((res: IResponse<any>) => {
  //                 console.log(res);
  //                 if (res.code === RESPONSE_CODE.SUCCESS) {
  //                   this.toastService.show('인증번호가 전송되었습니다.', 'toast1');
  //                 } else {
  //                   this.toastService.show(res.msg, 'toast1');
  //                 }
  //                 return {type: 'NO_ACTION'};
  //               })
  //             )
  //         },
  //         {dispatch: false}
  //       ))
  // })

  //
  // @Effect() accountLogout$ = this.actions$
  //   .ofType(AccountActions.ACCOUNT_LOGOUT)
  //   .switchMap((action: AccountActions.AccountLogout) => {
  //     return this.signService.logout()
  //       .map((res: IResponse<any>) => {
  //         this.toastService.show('로그아웃되었습니다.', 'toast1');
  //         if (res.code === RESPONSE_CODE.SUCCESS) {
  //           this.sessionService.destory();
  //           return new RouterActions.Go('SigninComponent');
  //         } else {
  //           this.toastService.show(res.msg, 'toast1');
  //           return {type: 'NO_ACTION'};
  //         }
  //       });
  //   });
}
