import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {TestComponentModule} from "./page/test/test.component.module";
import {ProductModule} from "./page/product/product.module";

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./page/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'account',
    loadChildren: () =>
      import('./page/account/account.component.module').then((m) => m.AccountComponentModule)
  },
  {
    path: 'search',
    loadChildren: () =>
      import('./page/search/search.module').then((m) => m.SearchModule),
  },
  {
    path: 'car',
    loadChildren: () =>
      import('./page/car/car.module').then((m) => m.CarModule),
  },
  {
    path: 'scanner',
    loadChildren: () =>
      import('./page/scanner/scanner.component.module').then((m) => m.ScannerModule),
  },
  {
    path: 'user',
    loadChildren: () =>
      import('./page/user/user.component.module').then((m) => m.UserComponentModule),
  },
  {
    path: 'item',
    loadChildren: () =>
      import('./page/item/item.component.module').then((m) => m.ItemComponentModule),
  },
  {
    path: 'board',
    loadChildren: () =>
      import('./page/board/board.component.module').then((m) => m.BoardComponentModule)
  },
  {
    path: 'inquiry',
    loadChildren: () =>
      import('./page/inquiry/inquiry.component.module').then((m) => m.InquiryComponentModule)
  },
  {
    path: 'product',
    loadChildren: () =>
      import('./page/product/product.module').then((m) => m.ProductModule)
  },
  {
    path: 'test',
    loadChildren: () =>
      import('./page/test/test.component.module').then((m) => m.TestComponentModule)
  },
  {
    path: 'address',
    loadChildren: () =>
      import('./page/address/address.component.module').then((m) => m.AddressComponentModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, onSameUrlNavigation: 'reload'})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
