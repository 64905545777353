import {Injectable} from "@angular/core";
import {HttpService} from '../service/http.service';
import {BaseApi} from './base.api';

@Injectable()
export class AuthApi extends BaseApi {

  constructor(http: HttpService) {
    super('auth', http);
  }

  public mobileSend(phone) {
    return this.http.post(`${this.controllerName}/mobile`, {phone: phone});
  }

  public mobileCheck(code) {
    return this.http.get(`${this.controllerName}/mobile/${code}`, {});
  }
}
