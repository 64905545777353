import {Injectable} from "@angular/core";
import {HttpService} from "../service/http.service";
import {BaseApi} from "./base.api";
import {Observable} from "rxjs/Observable";
import {IResponse} from "../service/response.service";

@Injectable()
export class PaymentApi extends BaseApi {

  constructor(http: HttpService) {
    super('payment', http);
  }

  public payment(): Observable<IResponse<any>> {
    return this.http.get<IResponse<any>>(`${this.controllerName}/inicis?os_type=web&order_id=5e6057ed88a99c41fb48c2b9&payment_type=Card`, {});
  }
}
