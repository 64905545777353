import {NgModule} from '@angular/core';
import {HttpService} from './http.service';
import {ToastService} from "./toast.service";
import {SessionService} from "./session.service";
import {AwsService} from "./aws.service";
import { SejongService } from './sejong.service';

@NgModule({
  imports: [],
  providers: [
    HttpService,
    ToastService,
    SessionService,
    AwsService,
    SejongService
  ]
})
export class ServiceModule {
}

