import {Injectable} from "@angular/core";
import {ToastController} from "@ionic/angular";

@Injectable()
export class ToastService {

  constructor(private toastCtrl: ToastController) { }

  async show(msg, css) {
    let toast = await this.toastCtrl.create({
      message: msg,
      duration: 3000,
      position: 'bottom',
      cssClass: css
    });

    await toast.present();
  }
}
