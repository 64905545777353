import {Injectable} from '@angular/core';
import {BaseApi} from './base.api';
import {HttpService} from '../service/http.service';
import {Observable} from "rxjs/Observable";
import {Inquiry} from "../model/inquiry";
import {IResponse} from '../service/response.service';

@Injectable()
export class InquiryApi extends BaseApi {
  constructor(http: HttpService) {
    super('inquiries', http);
  }

  public answer(inquiry: Inquiry): Observable<IResponse<any>> {
    const params = inquiry.answer;
    return this.http.put<IResponse<any>>(`${this.controllerName}/${inquiry._id}/answer`, {params}, true);
  }

  public me(offset:number, count:number, keyword?: string): Observable<IResponse<any>>{
    const params = {
      offset: offset,
      count: count,
      keyword: keyword
    };

    return this.http.get<IResponse<any>>(`${this.controllerName}/me`, params);
  }
}
