import {Observable} from 'rxjs/Observable';

import {HttpService} from '../service/http.service';
import {IModel} from '../model/interface';
import {IResponse} from "../service/response.service";

export class BaseApi {

  protected controllerName: string;
  protected http: HttpService;

  constructor(_controllerName: string, _http: HttpService) {
    this.controllerName = _controllerName;
    this.http = _http;
  }

  public add<T>(item: IModel, withFile: boolean = false): Observable<IResponse<T>> {
    return this.http.post(`${this.controllerName}`, item.toObject());
  }

  public get<T>(id: string): Observable<IResponse<IResponse<T>>> {
    return this.http.get<IResponse<T>>(`${this.controllerName}/${id}`);
  }

  public list<T>(offset: number, count: number, additional?: any): Observable<IResponse<T>> {
    const params = {
      count,
      offset
    };

    if (typeof additional !== 'undefined') {
      Object.keys(additional).forEach(key => {
        params[key] = additional[key];
      });
    }
    return this.http.get<IResponse<T>>(`${this.controllerName}`, params);
  }

  public post<T>(item: IModel): Observable<IResponse<T>> {
    return this.http.post(`${this.controllerName}`, item.toObject());
  }

  public modify<T>(item: IModel, withFile: boolean = false): Observable<IResponse<T>> {
    return this.http.put(`${this.controllerName}/${item._id}`, item.toObject(), withFile);
  }

  public delete<T>(id: string, pass?:string): Observable<IResponse<T>> {
    return this.http.delete(`${this.controllerName}/${id}`, {pass: pass});
  }

  report(id: string, state: number, reason: string, contact: string): Observable<IResponse<any>>{
    return this.http.put<IResponse<any>>(`${this.controllerName}/${id}/report`, {state: state, reason: reason, contact: contact});
  }
}
