import * as ClueActions from './clue.action';
import {ClueCallSuccess, ClueDetailSuccess, ClueListMeSuccess, ClueSearchSuccess, ClueSelect} from "./clue.action";
import {ClueState, IClueState} from "./clue.state";

export type Action = ClueActions.All;
export const initState: IClueState = ClueState as IClueState;

export function clueReducer(state: IClueState = initState, action: Action) {

  switch (action.type){
    case ClueActions.CLUE_LIST_ME_SUCCESS:
      return state.merge({
        me: action.clue
      });
    case ClueActions.CLUE_SEARCH_SUCCESS:
      return state.merge({
        search: (action as ClueSearchSuccess).clue
      });
    case ClueActions.CLUE_DETAIL_SUCCESS:
      return state.merge({
        detail: (action as ClueDetailSuccess).clue
      });
    case ClueActions.CLUE_CALL_SUCCESS:
      return state.merge({
        call: (action as ClueCallSuccess).call
      });
    case ClueActions.CLUE_SELECT:
      return state.merge({
        select: (action as ClueSelect).clue
      });
    default:
      return state;
  }
}
