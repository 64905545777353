import * as AccountActions from './action';
import {AccountState, IAccountState} from './state';
import { AccountCallSuccess, AccountGetLoginedSuccess, AccountGetMe, AccountMobileCheckCodeSuccess } from "./action";
import {User} from "../../model/user";
import {Converter} from "../../helper/converter";

export type Action = AccountActions.All;
export const initState: IAccountState = AccountState as IAccountState;

export function accountReducer(state: IAccountState = initState, action: Action) {
  switch (action.type) {
    case AccountActions.ACCOUNT_ADDRESS_SET_CODE:
      const address: User = Converter.jsonToInstance<User>(User, action);
      return state.merge({
        address: address
      });
    case AccountActions.ACCOUNT_ADDRESS_RESET_CODE:
      return state.merge({
        address: null
      });
    case AccountActions.ACCOUNT_MOBILE_CHECK_CODE_SUCCESS:
      return state.merge({
        isMobileAuth: (action as AccountMobileCheckCodeSuccess).result
      });
    case AccountActions.ACCOUNT_GET_LOGINED_SUCCESS:
      return state.merge({
        accountList: (action as AccountGetLoginedSuccess).login
      });
    case AccountActions.ACCOUNT_GET_ME:
      return state.merge({
        me: (action as AccountGetMe).user
      });
    case AccountActions.ACCOUNT_CALL_SUCCESS:
      return state.merge({
        call: (action as AccountCallSuccess).user
      });
    default:
      return state;
  }
}
