import {Injectable} from '@angular/core';
import {BaseApi} from './base.api';
import {HttpService} from "../service/http.service";

@Injectable()
export class BankApi extends BaseApi {
  constructor(http: HttpService) {
    super('banks', http);
  }
}
