import {Action} from "@ngrx/store";
import { Clue } from "../../model/clue";

export const CLUE_SELECT = 'CLUE_SELECT';
export class ClueSelect implements Action {
  readonly type = CLUE_SELECT;
  constructor(public clue: Clue){}
}

export const CLUE_ADD = 'CLUE_ADD';
export class ClueAdd implements Action  {
  readonly type = CLUE_ADD;
  constructor(public clue: Clue) {}
}

export const CLUE_DELETE = 'CLUE_DELETE';
export class ClueDelete implements Action {
  readonly type = CLUE_DELETE;
  constructor(public id: string){}
}

export const CLUE_MODIFY = 'CLUE_MODIFY';
export class ClueModify implements Action {
  readonly type = CLUE_MODIFY;
  constructor(public clue: Clue){}
}

export const CLUE_LIST = 'CLUE_LIST';
export class ClueList implements Action {
  readonly type = CLUE_LIST;
  constructor(public offset: number, public count: number) {}
}

export const CLUE_LIST_SUCCESS = 'CLUE_LIST_SUCCESS';
export class ClueListSuccess implements Action {
  readonly type = CLUE_LIST_SUCCESS;
  constructor(public clue: Clue) {}
}

export const CLUE_LIST_ME = 'CLUE_LIST_ME';
export class ClueListMe implements Action{
  readonly type = CLUE_LIST_ME;
  constructor(public offset:number, public count: number) {}
}

export const CLUE_LIST_ME_SUCCESS = 'CLUE_LIST_ME_SUCCESS';
export class ClueListMeSuccess implements Action {
  readonly type = CLUE_LIST_ME_SUCCESS;
  constructor(public clue: Clue) {
  }
}

export const CLUE_SEARCH = 'CLUE_SEARCH';
export class ClueSearch implements Action {
  readonly type = CLUE_SEARCH;
  constructor(public keyword: string) {}
}

export const CLUE_SEARCH_SUCCESS = 'CLUE_SEARCH_SUCCESS';
export class ClueSearchSuccess implements Action {
  readonly type = CLUE_SEARCH_SUCCESS;
  constructor(public clue: Clue) {}
}

export const CLUE_DETAIL = 'CLUE_DETAIL';
export class ClueDetail implements Action {
  readonly type = CLUE_DETAIL;
  constructor(public unique: string) {}
}

export const CLUE_DETAIL_SUCCESS = 'CLUE_DETAIL_SUCCESS';
export class ClueDetailSuccess implements Action {
  readonly type = CLUE_DETAIL_SUCCESS;
  constructor(public clue: Clue) {}
}

export const CLUE_CALL = 'CLUE_CALL';
export class ClueCall implements Action {
  readonly type = CLUE_CALL;
  constructor(public id: string) {
  }
}

export const CLUE_CALL_SUCCESS = 'CLUE_CALL_SUCCESS';
export class ClueCallSuccess implements Action {
  readonly type = CLUE_CALL_SUCCESS;
  constructor(public call: number) {}
}


export type All =
  ClueAdd |
  ClueDelete |
  ClueModify |
  ClueList |
  ClueListSuccess |
  ClueListMe |
  ClueListMeSuccess |
  ClueSearch |
  ClueSearchSuccess |
  ClueDetail |
  ClueDetailSuccess |
  ClueCall |
  ClueCallSuccess |
  ClueSelect
  ;
