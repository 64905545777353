import {List, Map} from 'immutable';
import {Inquiry} from "../../model/inquiry";

export interface IInquiryState extends Map<string, any> {
  list: List<Inquiry>;
  inquiry: Map<string, Inquiry>;
  pageNumber: number;
  totalCount: number;
}

export const InquiryState = Map({
  list: null,
  inquiry: Map<string, any>(),
  pageNumber: 1,
  totalCount: 0,
});
