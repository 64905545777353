import {Injectable} from "@angular/core";
import {BaseApi} from "./base.api";
import {HttpService} from "../service/http.service";
import {Observable} from "rxjs/Observable";
import {IResponse} from "../service/response.service";
import {Login} from "../model/login";

@Injectable()
export class LoginApi extends BaseApi {

  constructor(http: HttpService) {
    super('login', http);
  }

  public login(id: string, pass: string): Observable<IResponse<any>> {
    return this.http.post(`${this.controllerName}`, {id: id, pass: pass});
  }

  public loginGet(): Observable<IResponse<Login[]>> {
    return this.http.get(`${this.controllerName}`, {})
  }

  public logout(): Observable<IResponse<Login[]>> {
    return this.http.delete(`${this.controllerName}`, {});
  }
}
