import {RouterEffect} from './core/redux/router/router.effect';
import {ClueEffect} from "./core/redux/clue/clue.effect";
import {AccountEffect} from "./core/redux/account/effect";
import {InquiryEffect} from "./core/redux/inquiry/inquiry.effect";

export const appEffects = [
  RouterEffect,
  ClueEffect,
  AccountEffect,
  InquiryEffect
];
