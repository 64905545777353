import {Injectable} from "@angular/core";

import {HttpService} from "../service/http.service";
import {BaseApi} from "./base.api";

@Injectable()
export class ProductApi extends BaseApi{

  constructor(http: HttpService) {
    super('products', http);
  }

}
