import {Injectable} from "@angular/core";
import {HttpService} from "../service/http.service";
import {BaseApi} from "./base.api";
import {IResponse} from "../service/response.service";
import {Clue} from "../model/clue";
import {Observable} from "rxjs";

@Injectable()
export class ClueApi extends BaseApi {

  constructor(http: HttpService) {
    super('clues', http);
  }

  state(clue: Clue): Observable<IResponse<any>> {
    return this.http.put<IResponse<any>>(`${this.controllerName}/${clue._id}/state`, {call: clue.call, lost: clue.lost});
  }

  me(offset: number, count: number): Observable<IResponse<any>>{
    return this.http.get<IResponse<any>>(`${this.controllerName}/me`, {offset: offset, count: count});
  }

  search(keyword: string): Observable<IResponse<any>>{
    return this.http.get<IResponse<any>>(`${this.controllerName}/search`, {keyword: keyword});
  }

  detail(unique: string): Observable<IResponse<any>>{
    return this.http.get<IResponse<any>>(`${this.controllerName}/unique`, {unique: unique});
  }

  sejong(id: string): Observable<IResponse<any>>{
    return this.http.get<IResponse<any>>(`${this.controllerName}/sejong`, {unique: id});
  }

  check(id: string, pass: string): Observable<IResponse<any>>{
    return this.http.get<IResponse<any>>(`${this.controllerName}/check`, {_id: id, pass: pass});
  }

  report(id: string, state: number, reason: string, contact: string): Observable<IResponse<any>>{
    return this.http.put<IResponse<any>>(`${this.controllerName}/${id}/report`, {state: state, reason: reason, contact: contact});
  }

  encodeImg(url: string): Observable<IResponse<any>> {
    return this.http.get<IResponse<any>>(`${this.controllerName}/encodeImg`, {url: url});
  }
}
