import {Component} from '@angular/core';
import * as RouterActions from "./core/redux/router/router.action";
import {Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {AppStore} from "./app-store.interface";
import {Banner} from "./core/model/banner";
import {BannerApi} from "./core/api/banner.api";
import {Converter} from "./core/helper/converter";
import {RESPONSE_CODE} from "./core/service/response.service";
import {InAppBrowser} from "@awesome-cordova-plugins/in-app-browser/ngx";
import {SejongService} from "./core/service/sejong.service";
import {UserApi} from "./core/api/user.api";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public href: string = "";

  banner: Banner[] = [];

  bannerR: Banner[] = [];
  bannerL: Banner[] = [];

  initialize;

  constructor(
    private router: Router,
    private store: Store<AppStore>,
    private bannerApi: BannerApi,
    private iab: InAppBrowser,
    private sejongService: SejongService,
    private userApi: UserApi
  ) {

  }

  ngOnInit() {
    this.href = window.location.href;
    if (this.href.match('www.aliascall.com')) {
      const uniqueKey = this.href.split('/');
      if (uniqueKey[3] === 'main') {
        if (uniqueKey[5] !== 'undefined') {
          this.store.dispatch(new RouterActions.Go(['search/detail'], {unique: uniqueKey[5]}));
        } else {
          this.userApi.sejong(uniqueKey[6]).subscribe(
            async rs => {
              const call = rs.data.vn;
              await this.sejongService.call(call);
            }
          )
        }
      }
    }

    this.initialize = this.init();
  }

  async init() {
    await this.getBanner();
  }

  async getBanner() {
    const rs = await this.bannerApi.list(0, 0, {today: true}).toPromise();
    if (rs.code === RESPONSE_CODE.SUCCESS) {
      this.banner = Converter.jsonToInstance<Banner>(Banner, rs.data.list);
      this.banner.forEach((item, index) => {
          if ((index % 2) === 0) {
            this.bannerR.push(item);

            setInterval(() => {
              this.bannerR.sort(function () {
                return Math.random() - Math.random();
              });
            }, 5000);

          } else {
            this.bannerL.push(item);

            setInterval(() => {
              this.bannerL.sort(function () {
                return Math.random() - Math.random();
              });
            }, 5000);

          }
        }
      )
    }
  }

  toLinks(links) {
    this.iab.create(links, "_blank");
  }
}
