import {Injectable} from '@angular/core';
import {HttpService} from '../service/http.service';
import {BaseApi} from './base.api';
import {Observable} from 'rxjs/Observable';
import {IResponse} from '../service/response.service';

@Injectable()
export class FileApi extends BaseApi {
  constructor(http: HttpService) {
    super('aws', http);
  }

  getUploadUrl(): Observable<IResponse<any>> {
    return this.http.get<IResponse<any>>(`${this.controllerName}/uploadUrl`);
  }

  // getLoadUrl(): Observable<IResponse<any>> {
  //
  //
  // }
}
