import {Injectable} from "@angular/core";
import {HttpService} from "../service/http.service";
import {BaseApi} from "./base.api";
import {Observable} from "rxjs/Observable";
import {IResponse} from "../service/response.service";

@Injectable()
export class UserApi extends BaseApi {

  constructor(http: HttpService) {
    super('users', http);
  }

  isExist(user_id): Observable<IResponse<any>> {
    return this.http.get<IResponse<any>>(`${this.controllerName}/exist`, {user_id: user_id});
  }

  push(push: boolean): Observable<IResponse<any>> {
    return this.http.put<IResponse<any>>(`${this.controllerName}/me/push`, {push: push});
  }

  sejong(id: string): Observable<IResponse<any>>{
    return this.http.get<IResponse<any>>(`${this.controllerName}/sejong`, {id: id});
  }

  me(): Observable<IResponse<any>> {
    return this.http.get<IResponse<any>>(`${this.controllerName}/me`, {});
  }

  pass(oldPass: string, newPass:string): Observable<IResponse<any>> {
    return this.http.put<IResponse<any>>(`${this.controllerName}/pass`, {oldPass: oldPass, newPass: newPass})
  }

  putPhone(phone): Observable<IResponse<any>> {
    return this.http.put<IResponse<any>>(`${this.controllerName}/phone`,{phone: phone});
  }

  phone(_id: string): Observable<IResponse<any>>{
    return this.http.get<IResponse<any>>(`${this.controllerName}/phone`, {_id: _id});
  }

  deleteMe(): Observable<IResponse<any>>{
    return this.http.delete<IResponse<any>>(`${this.controllerName}/me`, {});
  }
}
