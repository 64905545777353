import {Injectable} from "@angular/core";
import {BaseApi} from "./base.api";
import {HttpService} from "../service/http.service";
import {Observable} from "rxjs/Observable";
import {IResponse} from "../service/response.service";

@Injectable()
export class SignApi extends BaseApi {

  constructor(http: HttpService) {
    super('sign', http);
  }

  public signin(id: string, pass: string): Observable<IResponse<any>> {
    return this.http.post(`${this.controllerName}/in`, {id: id, pass: pass});
  }

  public logout(): Observable<IResponse<any>> {
    return this.http.delete(`${this.controllerName}`, {});
  }
}
