import {Injectable} from '@angular/core';
import {Actions, ofType, createEffect} from '@ngrx/effects';
import {NavigationExtras, Params, Router} from '@angular/router';
import {Location} from '@angular/common';
import * as RouterActions from './router.action';
import {map} from 'rxjs/operators';
import {AppStore} from '../../../app-store.interface';
import {Store} from '@ngrx/store';

@Injectable()
export class RouterEffect {

  navigate$ = createEffect(() => this.actions$.pipe(
      ofType(RouterActions.GO),
      map((action: RouterActions.Go) => {
        const extras: NavigationExtras = {};
        /**
         * URL Query Params 추가
         */
        if (action.queryParams) {
          extras.queryParams = action.queryParams;
        }
        /**
         * Developer Data 추가
         */
        if (action.state) {
          extras.state = action.state;
        }
        /**
         * 기타 NavigationExtras 옵션 추가
         */
        if (action.options) {
          for (const key in action.options) {
            if (key) {
              extras[key] = action.options[key];
            }
          }
        }
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        return this.router.navigate(action.commands, extras);
      })
    ),
    {dispatch: false}
  );

  navigateBack$ = createEffect(() => this.actions$.pipe(
      ofType(RouterActions.BACK),
      map(() => {
        return this.location.back();
      })
    ),
    {dispatch: false}
  );

  navigateForward$ = createEffect(() => this.actions$.pipe(
      ofType(RouterActions.FORWARD),
      map(() => this.location.forward())
    ),
    {dispatch: false}
  );

  constructor(
    private store: Store<AppStore>,
    private actions$: Actions,
    private router: Router,
    private location: Location,
    // private spinnerService: SpinnerService
  ) {
  }
}
