import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from "@angular/common/http";
import {Observable, of} from "rxjs";
import {Injectable} from "@angular/core";
import {AppStore} from "../../app-store.interface";
import {Store} from "@ngrx/store";

import {catchError, filter, tap} from "rxjs/operators";
import * as RouterActions from '../redux/router/router.action';
import {IResponse, RESPONSE_CODE} from "../service/response.service";

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {

    constructor(private store: Store<AppStore>) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next
            .handle(req)
            .pipe(
                catchError(error => of(error)),
                filter((ev: HttpEvent<any>) => ev instanceof HttpResponse),
                tap((res: HttpResponse<IResponse<any>>) => {
                  if(res.body){
                    switch (res.body.code) {
                      case RESPONSE_CODE.SUCCESS:
                        break;
                      case RESPONSE_CODE.NOT_AUTHENTICATION:
                        this.store.dispatch(new RouterActions.Go(['account/signin']));
                        break;
                      case RESPONSE_CODE.NOT_GRANTED:
                        this.store.dispatch(new RouterActions.Back());
                        break;
                      case RESPONSE_CODE.NO_RESULT:
                        this.store.dispatch(new RouterActions.Back());
                        break;
                      default:
                        return res;
                        break;
                    }
                  }
                })
            );
    }
}
