import {Injectable} from "@angular/core";
import {AlertController, Platform} from "@ionic/angular";

@Injectable()
export class SejongService {

  constructor(
    private alertCtrl: AlertController,
    private platform: Platform
  ) { }

  async call(call) {
    let alert = await this.alertCtrl.create({
      message: '등록자와 통화를 연결하시겠습니까?',
      buttons: [
        {
          text: '취소',
          handler: () => {}
        },
        {
          text: '연결',
          handler: () => {
            window.open(`tel:${call}`);
          }
        }
      ]
    });
    await alert.present();
  }

  async sms(call) {
    if(this.platform.is('ios')){
      location.href=`sms:${call}`;
    } else {
      location.href=`sms:${call}`;
    }
  }

  async desktop(call){
    const alert = await this.alertCtrl.create({
      subHeader: `안심번호가 발급되었습니다. 등록자와 연락을 진행해주세요.`,
      message: `${call}`,
      cssClass: 'ion-text-center'
    })
    await alert.present();
  }
}
