import {Injectable} from '@angular/core';
import {Actions, createEffect, Effect, ofType} from '@ngrx/effects';

import * as ClueActions from './clue.action';
import * as RouterActions from '../../../core/redux/router/router.action';

import {ClueApi} from "../../api/clue.api";
import {Clue} from "../../model/clue";
import {IResponse, RESPONSE_CODE} from "../../service/response.service";
import {ToastService} from "../../service/toast.service";
import {Converter} from "../../helper/converter";
import {catchError, map, switchMap} from "rxjs/operators";
import {of} from "rxjs";

@Injectable()
export class ClueEffect {

  constructor(
    private actions$: Actions,
    private clueApi: ClueApi,
    private toastService: ToastService
  ) {
  }

  add$ = createEffect(() => this.actions$.pipe(
      ofType(ClueActions.CLUE_ADD),
      switchMap((action: ClueActions.ClueAdd) => {
        return this.clueApi.add(action.clue)
          .pipe(
            map((res: IResponse<any>) => {
              if (res.code === RESPONSE_CODE.SUCCESS) {
                return new RouterActions.Go(['user/clue/list']);
              } else {
                if (res.code == RESPONSE_CODE.INVALID_PARAMETER) {
                  this.toastService.show('모든 항목을 입력해주세요.', 'toast1');
                } else {
                  this.toastService.show(res.msg, 'toast1');
                }
                return {type: "NO_ACTION"};
              }
            }))
      })
    ),
    {dispatch: false}
  )

  list$ = createEffect(() => this.actions$.pipe(
      ofType(ClueActions.CLUE_LIST),
      switchMap((action: ClueActions.ClueList) => {
        return this.clueApi.list(action.offset, action.count)
          .pipe(
            map((res: IResponse<any>) => {
              if (res.code === RESPONSE_CODE.SUCCESS) {
                const clue: Clue = Converter.jsonToInstance<Clue>(Clue, res.data);
                return new ClueActions.ClueListSuccess(clue);
              } else {
                return {type: "NO_ACTION"};
              }
            })
          )
      })
    ),
    {dispatch: false}
  )

  listMe$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ClueActions.CLUE_LIST_ME),
      switchMap((action: ClueActions.ClueListMe) => {
        return this.clueApi.me(action.offset, action.count)
          .pipe(
            map((res: IResponse<any>) => {
              if (res.code === RESPONSE_CODE.SUCCESS) {
                const clue: Clue = Converter.jsonToInstance<Clue>(Clue, res.data);
                return new ClueActions.ClueListMeSuccess(clue);
              }
            })
            , catchError(rs => of(rs))
          )
      })
    )
  })

  modify$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ClueActions.CLUE_MODIFY),
      switchMap((action: ClueActions.ClueModify) => {
        return this.clueApi.modify(action.clue)
          .pipe(
            map((res: IResponse<any>) => {
              if (res.code === RESPONSE_CODE.SUCCESS) {
                this.toastService.show('수정이 완료되었습니다.', 'toast1');
                return new ClueActions.ClueListMe(0, 100);
              }
            })
            , catchError(rs => of(rs))
          )
      })
    )
  })

  delete$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ClueActions.CLUE_DELETE),
      switchMap((action: ClueActions.ClueDelete) => {
        return this.clueApi.delete(action.id)
          .pipe(
            map((res: IResponse<any>) => {
              if (res.code === RESPONSE_CODE.SUCCESS) {
                this.toastService.show('삭제되었습니다.', 'toast1');
                return new ClueActions.ClueListMe(0, 100);
              }
            })
            , catchError(rs => of(rs))
          )
      })
    )
  })

  // @Effect() delete$ = this.actions$.ofType(ClueActions.CLUE_DELETE)
  //   .switchMap((action: ClueActions.ClueDelete) =>
  //     this.clueApi.delete(action.id)
  //       .map((res: IResponse<any>) => {
  //         if (res.code === RESPONSE_CODE.SUCCESS) {
  //           return new RouterActions.Go(['user/clue/list']);
  //         } else {
  //           return {type: "NO_ACTION"};
  //         }
  //       })
  //   );
  //

  // @Effect() add$ = this.actions$.ofType(ClueActions.CLUE_ADD)
  //   .switchMap((action: ClueActions.ClueAdd) =>
  //     this.clueApi.add(action.clue)
  //       .map((res: IResponse<any>) => {
  //         if(res.code === RESPONSE_CODE.SUCCESS) {
  //           return new RouterActions.Go(['user/clue/list']);
  //         }else{
  //           if(res.code == RESPONSE_CODE.INVALID_PARAMETER){
  //             this.toastService.show('모든 항목을 입력해주세요.', 'toast1');
  //           }else{
  //             this.toastService.show(res.msg, 'toast1');
  //           }
  //           return {type: "NO_ACTION"};
  //         }
  //       })
  //   );

  // @Effect() delete$ = this.actions$.ofType(ClueActions.CLUE_DELETE)
  //   .switchMap((action: ClueActions.ClueDelete) =>
  //     this.clueApi.delete(action.id)
  //       .map((res: IResponse<any>) => {
  //         if (res.code === RESPONSE_CODE.SUCCESS) {
  //           return new RouterActions.Go(['user/clue/list']);
  //         } else {
  //           return {type: "NO_ACTION"};
  //         }
  //       })
  //   );
  //


  // @Effect() list$ = this.actions$.ofType(ClueActions.CLUE_LIST)
  //   .switchMap((action: ClueActions.ClueList) =>
  //     this.clueApi.list(action.offset, action.count)
  //       .map((res: IResponse<any>) => {
  //         if (res.code === RESPONSE_CODE.SUCCESS) {
  //           const clue: Clue = Converter.jsonToInstance<Clue>(Clue, res.data);
  //           return new ClueActions.ClueListSuccess(clue);
  //         } else {
  //           return {type: "NO_ACTION"};
  //         }
  //       })
  //   );

  //
  // @Effect() search$ = this.actions$.ofType(ClueActions.CLUE_SEARCH)
  //   .switchMap((action: ClueActions.ClueSearch) =>
  //     this.clueApi.search(action.keyword)
  //       .map((res: IResponse<any>) => {
  //         if (res.code === RESPONSE_CODE.SUCCESS) {
  //           if (res.data !== null) {
  //             const clue: Clue = Converter.jsonToInstance<Clue>(Clue, res.data);
  //             return new ClueActions.ClueSearchSuccess(clue);
  //           } else {
  //             return {type: "NO_ACTION"};
  //           }
  //         } else {
  //           return {type: "NO_ACTION"};
  //         }
  //       })
  //   );
  //
  // @Effect() detail$ = this.actions$.ofType(ClueActions.CLUE_DETAIL)
  //   .switchMap((action: ClueActions.ClueDetail) =>
  //     this.clueApi.detail(action.unique)
  //       .map((res: IResponse<any>) => {
  //         if (res.code === RESPONSE_CODE.SUCCESS) {
  //           const clue: Clue = Converter.jsonToInstance<Clue>(Clue, res.data);
  //           return new ClueActions.ClueDetailSuccess(clue);
  //         } else {
  //           return {type: 'NO_ACTION'};
  //         }
  //       })
  //   );
  //
  // @Effect() call$ = this.actions$.ofType(ClueActions.CLUE_CALL)
  //   .switchMap((action: ClueActions.ClueCall) =>
  //     this.clueApi.sejong(action.id)
  //       .map((res: IResponse<any>) => {
  //         if (res.code === RESPONSE_CODE.SUCCESS) {
  //           const phone: number = Converter.jsonToInstance<Clue>(Clue, res.data);
  //           return new ClueActions.ClueCallSuccess(phone);
  //         } else {
  //           return {type: 'NO_ACTION'};
  //         }
  //       })
  //   )
}
