import {Serializable} from '../helper/serializable';
import {IModel} from './interface';

export class Banner extends Serializable implements IModel {

  _id: string;
  title: string;
  description: string;
  img: any;
  startDate: any;
  endDate: any;
  links: any;
  date: any;

  constructor(
    _id?: string,
    title?: string,
    description?: string,
    img?: any,
    startDate?: any,
    endDate?: any,
    links?: any
  ) {
    super();
    this._id = _id;
    this.title = title;
    this.description = description;
    this.img = img;
    this.startDate = startDate;
    this.endDate = endDate;
    this.links = links;
  }

  toObject(): object {
    return {
      _id: this._id,
      title: this.title,
      description: this.description,
      img: this.img,
      startDate: this.startDate,
      endDate: this.endDate,
      links: this.links
    };
  }
}
