import {Store} from '@ngrx/store';

import {AppStore} from '../../app-store.interface';
import {SessionService} from '../service/session.service';
import * as RouterActions from '../../core/redux/router/router.action';
import {Observable} from "rxjs/Observable";
import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from "@angular/router";

@Injectable()
export class CanActiveViaAuthGuard implements CanActivate {

  constructor(
    private store: Store<AppStore>,
    private router: Router,
    private sessionService: SessionService
  ) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise<boolean>(async (resolve, reject) => {
      const isAuthenticated = await this.sessionService.isAuthenticated().toPromise();
      if (isAuthenticated) {
        resolve(true);
      } else {
        const isRefreshed = await this.sessionService.refresh().toPromise();
        if (!isRefreshed) {
          this.sessionService.destroy();
          this.store.dispatch(new RouterActions.Go(['account/signin']));
          resolve(false);
        } else {
          resolve(true);
        }
      }
    });
  }
}
